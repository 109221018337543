<template>
  <div class="recycle-drink-item">
    <div class="variable">
      <div class="variable-line title" v-if="depth ===0">
        <div class="variable-line-item tf">参数名称</div>
        <div class="variable-line-item tf ">参数值</div>
        <div class="variable-line-item ">参数说明</div>
      </div>
    </div>
    <div v-if="nodes.length >0" class="variable">

      <div v-for="(node, index) in nodes"
           :key="index"
           class="variable-line variable-content"
           :class="(node.type  === 2)?'variable-div':''">
        <div class="variable-line-item tf " :style="indent">
          <i class="iconfont iconzuzhiqunzu"  v-if="node.type  === 2"></i> {{node.name}}</div>
        <div class="variable-line-item tf " :style="indent">
          <el-input-number v-model="node.defaultValue" type="number" size="small"
                    v-if="node.type===0" :step="node.step" :min="node.min" :max="node.max"
                    controls-position="right" step-strictly></el-input-number>
          <el-switch v-model="node.defaultValue" size="small" v-if="node.type===1"
                     :active-value="true" :inactive-value="false"></el-switch>
        </div>
        <div class="variable-line-item" v-if="node.type  !== 2">{{node.unitName}}</div>
        <div class="variable-line-item" v-else>-</div>
        <recycle-drink-item v-if="node.nodes" :nodes="node.nodes"
                            :depth="depth + 1"></recycle-drink-item>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'recycle-drink-item',
    props: ['nodes', 'depth'],
    components: {},
    mounted: function () {
    },
    computed: {
      indent() {
        return {transform: `translate(${this.depth * 10}px)`}

      },
    },
    filters: {
      filterType(type) {
        switch (type) {
          case 0:
            return '整数值'
          case 1:
            return '布尔值'
          case 2:
            return '组合参数'
        }
      }
    },
    data: function () {
      return {
        types: [
          {name: '整数值', value: 0},
          {name: '布尔值', value: 1},
          {name: '组合参数', value: 2},

        ]
      }
    },
    methods: {
    },
  }
</script>

<style lang="scss" scoped>
  .recycle-drink-item {
    width: 100%;

    .variable {
      &-line {
        display: flex;
        flex-flow: row wrap;

        &-item {
          display: inline-block;
          flex: 1;
          text-align: center;

          .el-input {
            .el-input__inner{
              padding: 0;
              padding-left: 15px;
            }

            width: 25%;
          }

        }

        .tf {
          text-align: left;
        }

        .tr {
          text-align: right;
        }

        .margin {
          margin-right: $middle-space;
        }

      }

      .title {
        margin: 8px 0;
      }


      &-content {
        .variable-line-item {
          font-size: 13px;
          line-height: 2;
          margin-bottom: $small-space;
        }
      }

      &-div:before {
        content: "";
        position: relative;
        margin-bottom: $small-space;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        z-index: 1;
        background-color: $theme-light-6;
      }
    }
  }
</style>
