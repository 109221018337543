<template>
  <div class="create-param">

    <create-param-item class="create-param-item" :nodes="nodes" :depth="0"></create-param-item>

    <div class="create-param-submit">
      <el-button type="primary" @click="$emit('preview')" ghost>上一步</el-button>
      <el-button type="primary" @click="handleCommit">提交</el-button>
    </div>

  </div>

</template>

<script>
  import CreateParamItem from './create_param_item'

  export default {
    name: '',
    props: {
      drink: {
        type: Object,
        default: () => {
          return {}
        }
      },
      select: {
        type: Boolean,
        default: true
      },
      load: {
        type: Boolean,
        default: true
      },
    },
    components: {
      CreateParamItem
    },
    mounted: function () {
      if (this.select) {
        if (this.drink.productUuid !== ``) {
          this.$api('equipment.models.cnf', {uuid: this.drink.productUuid}).then(res => {
            this.nodes = res.data.params
          })
        }
      } else {
        this.nodes = JSON.parse(this.drink.params)
      }
    },
    watch: {
      'drink.modelUuid': {
        handler(v) {
          if (v !== ``) {
            this.$api('equipment.models.cnf', {uuid: v}).then(res => {
              this.nodes = res.data.params
            })
          }

        },
        deep: true,
        immediate: true,
      }
    },
    data: function () {
      return {
        nodes: []
      }
    },
    methods: {
      handleCommit() {
        this.drink.params = JSON.stringify(this.nodes)
        this.$emit('next')
      }
    },
  }
</script>

<style lang="scss" scoped>
  .create-param {

    &-item {
      padding: 0 $middle-space;
      min-height: 320px;
    }

    &-submit {
      margin-top: $small-space;
      text-align: center;

    }

  }
</style>
